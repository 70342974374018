import React,{useState,useRef, useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Save,Add} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import $ from 'jquery'
import { useEffect } from 'react';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_qot_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [usr,setUsr]=useState([]);
    const [prd,setPrd]=useState([]);
    const [selectUsr, setSelectUsr] =useState('');
    const [selectPrd, setSelectPrd] =useState('');
    const [qte, setQte] =useState('');
    const [quota, setQouta] =useState(100);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [open, setOpen] = useState(false);
    const service="_prt.php"

    $(document).ready(function(){
      $('#tel-edit').on('input', function(ev) {
        var $this = $(this);
        var value = $this.val();
        if (value && value.length >10) {
          $this.val(value.substr(0,10));
        }
      })
      });


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = () => { 
      setOpen(true); 
      setQte('');setSelectPrd('');setSelectUsr('');setQouta(100)
    };

    const handleClose = () => { setOpen(false); };

    //fetch utilisateur
    const fetchUsr= useCallback(async(serv='_usr.php', act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err){
            props.handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                setUsr(response.data.jData);    
              }
            })
        }
    },[Gfunc,props])

    //fetch utilisateur handler
    const handleOpenUsr= useCallback(async () => {
        if(usr.length===0) fetchUsr()
    },[usr,fetchUsr])

     //fetch products 
  const fetchPrd= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_prd.php","gets")
    if(response.data.err){
      props.handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
           if(response.data.jData){
              setPrd(response.data.jData);   
           }
        })
    }
  },[Gfunc,props]);

  //handle the call for fetch products
  const handleOpenPrd=(event)=>{
      if(prd.length===0)  fetchPrd();
  }

  //fetch quota
  const fetchQouta= useCallback(async() => {
      var produit=''
      const response = await Gfunc.axiosGetAction(service,"getqusr");
      if(response.data.err){
          props.handleAlert(true,'error',response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
                 let indexCaractere = selectPrd.indexOf('[');
                 if(indexCaractere!==-1){
                       produit= selectPrd.slice(0,indexCaractere-1)
                 }
                 var index=response.data.jData.findIndex((obj =>(obj.produit===produit)&&(obj.agent===selectUsr))) 
                 if(index!==-1){
                    setQte(response.data.jData[index].quota)
                 }else{
                    setQte(0)
                }
          })
      }
    },[Gfunc,selectPrd,selectUsr])

    useEffect(() => {
      if(selectPrd!==''&&selectUsr!==''){fetchQouta(); }
    }, [fetchQouta,selectPrd,selectUsr]);

    const handleSubmit= async(e) =>{
      var produit=(selectPrd)?prd[Gfunc.getIndex(prd,selectPrd,"name")].id:'';
      var agent=(selectUsr)?usr[Gfunc.getIndex(usr,selectUsr,"name")].id:'';
      const response = await Gfunc.axiosPostAction(service,"addqota", {produitId:produit,userId:agent,qte:quota})
      if(response.data.err){
          handleAlert(true,'error',response.data.err)
      }else{
            Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                   props.handleAlert(true,"success","Un nouveau quota a été ajouté avec succès")
                   props.callback()
                   setOpen(false)
                }
         })
        }
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVEAU QUOTA</Button>
      <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau quota</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="frnsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        onChange={(e,newValue) =>{setSelectUsr(newValue)} } 
                        id='iUsr' 
                        onFocus={handleOpenUsr}
                        value={selectUsr}
                        options={usr.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField {...params} label='Agent' size="small" margin='dense' variant="outlined" required />)} 
                    />
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        onChange={(e,newValue) =>{setSelectPrd(newValue)} } 
                        id='iPrd' 
                        onFocus={handleOpenPrd}
                        value={selectPrd}
                        options={prd.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField {...params} label='Produit' size="small" margin='dense' variant="outlined" required />)} 
                    />
                    <div id='qot-row'>
                      <TextField type='number' className='smallText' label="Quota" size="small" value={quota} variant="outlined"
                                onChange={e =>setQouta(e.target.value)} required/>
                      {
                        (qte!=='')?
                         <TextField className='smallText' label="Quota actuel" size="small" variant="outlined" value={qte}
                                 onChange={e =>setQte(e.target.value)} disabled/>
                        :null
                      }
                      
                    </div>
              </ValidatorForm>
           </DialogContent>
           <DialogActions>
               <Button variant="contained" type='submit' form="frnsForm" color="primary" startIcon={<Save/>}>VALIDER</Button>
               <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
           </DialogActions>
      </Dialog>
      </div>
  );
}
